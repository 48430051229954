import { onLoad } from './libs/helpers';

let slideIndex = 1;

onLoad(() => {
  window['plusSlides'](slideIndex);
});

// Next/previous controls
window['plusSlides'] = n => {
  window['showSlides']((slideIndex += n));
};

// Thumbnail image controls
window['currentSlide'] = n => {
  window['showSlides']((slideIndex = n));
};

window['showSlides'] = n => {
  var i;
  var slides = document.getElementsByClassName('mySlides');
  if (slides.length === 0) {
    return; // no slideshow on this page
  }
  var dots = document.getElementsByClassName('demo');
  var captionText = document.getElementById('caption');
  if (n > slides.length) {
    slideIndex = 1;
  }
  if (n < 1) {
    slideIndex = slides.length;
  }
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = 'none';
  }
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(' active', '');
  }
  slides[slideIndex - 1].style.display = 'block';
  dots[slideIndex - 1].className += ' active';
  if (captionText) {
    captionText.innerHTML = dots[slideIndex - 1].alt;
  }
};
